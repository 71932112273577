import {AppProps} from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import localFont from 'next/font/local';
// eslint-disable-next-line
import {Cormorant, Merriweather, Roboto_Condensed} from 'next/font/google';
import dynamic from 'next/dynamic';
import 'shared/styles/global.css';
import {switchSpace} from 'shared/utils';

const PreviewProvider = dynamic(() =>
  import('components/PreviewProvider').then(
      ({PreviewProvider}) => PreviewProvider,
  ),
);

const moderat = localFont({
  src: [
    {
      path: '../font/Moderat-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../font/Moderat-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../font/Moderat-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
  display: 'swap',
});

const merriweather = Merriweather({subsets: ['latin'], weight: ['400']});
const robotoCondensed = Roboto_Condensed({subsets: ['latin'], weight: ['400']});
const cormorant = Cormorant({subsets: ['latin']});

const displayFont = switchSpace({
  'mindful.org': merriweather,
  'else': cormorant,
});
const headingFont = switchSpace({
  'mindful.org': robotoCondensed,
  'else': moderat,
});
const bodyFont = switchSpace({
  'mindful.org': merriweather,
  'else': moderat,
});


const App = ({
  Component,
  pageProps,
}: AppProps<{
  draftMode: boolean;
  token: string;
}>) => {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0"
        />
      </Head>
      <style jsx global>{`
        :root {
          --font-display: ${displayFont.style.fontFamily};
          --font-heading: ${headingFont.style.fontFamily};
          --font-body: ${bodyFont.style.fontFamily};
        }
      `}</style>
      {pageProps.draftMode ? (
        <PreviewProvider token={pageProps.token}>
          <Component {...pageProps} />
        </PreviewProvider>
      ) : (
        <Component {...pageProps} />
      )}

      {/* Microsoft Clarity */}
      <Script id="Clarity" strategy="afterInteractive">
        {`
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "ecuhn9hbef");
        `}
      </Script>
      {/**
       * Apple js sdk
       */}
      <Script
        type="text/javascript"
        src="https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js"
        strategy="afterInteractive"
        onReady={() => {
          AppleID.auth.init({
            clientId: process.env.APPLE_ID,
            redirectURI: process.env.APPLE_CALLBACK,
            scope: 'name email',
            state: 'origin:web',
            usePopup: true,
          });
        }}
      />
    </>
  );
};

export default App;
