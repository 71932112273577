import {isDefined} from '@mindfulness/utils/maybe';
import {onClient} from '../utils/next';

export const clearValue = (key: string) =>
  onClient(() => localStorage.removeItem(key));

export const setValue = (key: string, value: string) =>
  onClient(() => localStorage.setItem(key, value));

export const getValue = (key: string) =>
  onClient(() => {
    const v = localStorage.getItem(key);
    return v !== null ? v : undefined;
  });

export const isSet = (key: string) => isDefined(getValue(key));

export const setOnce = (key: string, value: string) =>
  onClient(() => {
    if (!isSet(key)) {
      setValue(key, value);
    }
  });

export const getSessionValue = (key: string) =>
  onClient(() => {
    const v = sessionStorage.getItem(key);
    return v !== null ? v : undefined;
  });

export const clearSessionValue = (key: string) =>
  onClient(() => sessionStorage.removeItem(key));

export const setSessionValue = (key: string, value: string) =>
  onClient(() => sessionStorage.setItem(key, value));
