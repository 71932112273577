import {switchEnum} from '@mindfulness/utils/logic';
import {when} from '@mindfulness/utils/maybe';
import {PlanFragment} from '../types/api';

/**
 * Fixes any decimal places to 2. Removes trailing zeros. Prepends a dollar sign.
 * @param {number} money - The amount of money to format.
 * @return {string}
 */
export const formatMoney = (money: number) =>
  when(money, (m) => `$${m.toFixed(2).replace('.00', '')}`);

export const billedWeekly = ({
  renewalInterval,
  price,
}: {
  renewalInterval: PlanFragment['renewalInterval'];
  price: number;
}) => {
  return switchEnum(renewalInterval, {
    NEVER: () => 0,
    YEAR: () => price / 52,
    MONTH: () => (price * 12) / 52,
    else: 0,
  });
};
