import {Maybe, when} from '@mindfulness/utils/maybe';

export const omitFalsey = <T>(arr: Array<Maybe<T>>) =>
  arr.filter(Boolean) as Array<T>;

export const convertToColumns = <T>(
  arr: Array<T>,
  cols: number,
): Maybe<Array<Array<T>>> =>
    when(arr, (a) => {
      const result: T[][] = [];
      const rows = Math.ceil(a.length / cols);
      for (let i = 0; i < a.length; i += rows) {
        result.push(a.slice(i, i + rows));
      }
      return result;
    });

/**
 * Adds or removes an item from an array based on whether it is already in it or not.
 * If it has to add item and the array is at its max length it will remove the first item.
 * @param {Array<T>} arr  - Array that you want to toggle an item in
 * @param {T} item - The item that you want to add or remove
 * @param {number} maxLength - The maximum length the array can be
 * @return {Array<T>} - Returns the array with the item added or removed
 */
export function togglePushArrayItem<T>(
    arr: Array<T>,
    item: T,
    maxLength: Maybe<number>,
): Array<T> {
  if (arr.includes(item)) {
    return removeArrayItem(arr, item);
  }
  if (maxLength && arr.length === maxLength) {
    // eslint-disable-next-line no-unused-vars
    const [_first, ...rest] = arr;
    return addArrayItem(rest, item);
  }
  return addArrayItem(arr, item);
}

/**
 * Adds or removes an item from an array based on whether it is already in it or not
 * @param {Array<T>} arr  - Array that you want to toggle an item in
 * @param {T} item - The item that you want to add or remove
 * @return {Array<T>} - Returns the array with the item added or removed
 */
export function toggleArrayItem<T>(arr: Array<T>, item: T): Array<T> {
  if (arr.includes(item)) {
    return removeArrayItem(arr, item);
  }
  return addArrayItem(arr, item);
}
/**
 * Adds an item to an array
 * @param {Array<T>} arr  - Array that you want to add the item to
 * @param {T} item - The item that you want to add
 * @return {Array<T>} - Returns the array with the item added
 */
export function addArrayItem<T>(arr: Array<T>, item: T): Array<T> {
  return [...arr, item];
}
/**
 * Removes an item from an arra
 * @param {Array<T>} arr  - Array that you want to remove the item from
 * @param {T} item - The item that you want to remove
 * @return {Array<T>} - Returns the array with the item removed
 */
export function removeArrayItem<T>(arr: Array<T>, item: T): Array<T> {
  return arr.filter((i) => i !== item);
}

/**
 * Removes any array from an array if it exists in another array
 * @param {Array<T>} arr  - Array that you want to remove the item from
 * @param {Array<T>} items - The item that you want to remove
 * @return {Array<T>} - Returns the array with the items removed
 */
export function removeArrayItems<T>(arr: Array<T>, items: Array<T>): Array<T> {
  return arr.filter((item) => !items.includes(item));
}

/**
 *
 * @param {Array<string>} arr - array of strings to sort alphabetically
 * @return {Array<string>}
 */
export function sortAlphabetically(arr: Array<string>) {
  return arr.sort((a, b) => a.localeCompare(b));
}

/**
 *
 * @param {Array<object>} arr - array of strings to sort alphabetically
 * @return {object}
 */
export function convertToObject(
    arr: Array<{
    name: string;
    value: string;
  }>,
): Record<string, string> {
  return arr
      .filter(({name, value}) => Boolean(name) && Boolean(value))
      .reduce<Record<string, string>>((obj, field) => {
        obj[field.name] = field.value;
        return obj;
      }, {});
}

export const has = <T>(
  arr: Array<T>,
  op: (item: T, index: number) => boolean,
): boolean => {
  return !!arr.find(op);
};

/**
 * Group array items into groups of count
 * @param {Array<T>} arr - array of items to group
 * @param {Number} count - number of items per group
 * @return {Array<Array<T>>}
 */
export const groupBy = <T>(arr: Array<T>, count: number) => {
  return arr.reduce<Array<T>>(function(result, _value, index, array) {
    if (index % count === 0) {
      result.push(array.slice(index, index + count) as T);
    }
    return result;
  }, [] as Array<T>);
};
