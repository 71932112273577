import {CheckoutType, Maybe, ReferencedAction} from '../types/types';
import {switchEnum} from '@mindfulness/utils/logic';
import {assertString} from './string';

export const isCheckoutAction = (action: ReferencedAction['action']): boolean =>
  !!action &&
  [
    'purchaseGiftCard',
    'purchaseSubscription',
    'purchaseEvent',
    'purchaseBogo',
    'redeemGuestpass',
    'purchase',
  ].includes(action);

export const getPageCheckoutType = (
    action: ReferencedAction['action'] | 'purchaseSubscription',
): Maybe<CheckoutType> => {
  return switchEnum(assertString(action, 'purchaseSubscription'), {
    purchaseBogo: 'bogo',
    purchaseEvent: 'event',
    purchaseGiftCard: 'gift',
    else: undefined,
  }) as Maybe<CheckoutType>;
};
