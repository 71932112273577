var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"f13776511691e5350d181e9155c42ab757161c52"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {definetly} from '@mindfulness/utils/definetly';
import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn: definetly(process.env.SENTRY_DSN, 'Sentry DSN missing.'),
  tunnel: '/api/sentry',
  maxBreadcrumbs: 50,
  attachStacktrace: true,
  environment: process.env.IS_LOCAL ? 'local' : process.env.INFRA_ENV,
  release: process.env.WEB_VERSION,
  beforeSend: (event, hint) => {
    console.debug(event, hint);
    return event;
  },
  ignoreErrors: [
    'Error: Minified React error #421',
    'Token sent to email.',
    'Failed to fetch',
    'SecurityError',
    'ResizeObserver',
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    'Can\'t find variable: ZiteReader',
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
  ],
  denyUrls: [
    // Facebook flakiness
    'graph.facebook.com',
    // Facebook blocked
    'connect.facebook.net',
    // Mixpanel blocked
    'cdn.mxpnl.com',
    // Impact blocked
    'utt.impactcdn.com',
    // Appsflyer blocked
    'websdk.appsflyer.com',
    // Stripe metrics blocked
    'm.stripe.com',
    'api.segment.io',
    // Clarity blocked
    'clarity.ms',
    // Vercel vitals blocked
    'vitals.vercel-insights.com',
    // Pinterest blocked
    's.pinimg.com',
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,

  // replaysOnErrorSampleRate: 1.0,

  // // This sets the sample rate to be 10%. You may want this to be 100% while
  // // in development and sample at a lower rate in production
  // replaysSessionSampleRate: 0.1,

  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [
    // Sentry.replayIntegration({
    //   // Additional Replay configuration goes in here, for example:
    //   maskAllText: true,
    //   blockAllMedia: true,
    // }),
  ],
});
