import {composel} from '@mindfulness/utils/fn';
import {Maybe, isString, when} from '@mindfulness/utils/maybe';
import {omitEmpty} from '@mindfulness/utils/object';
import {replace, trim} from 'lodash/fp';

export const capitalizeFirstLetter = (string?: string) =>
  when(string, (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`);

export const emailRegex = '^([a-z0-9_+.-]+@[da-z.-]+.[a-z.]{2,6})$';
export const validateEmail = (email: string) =>
  Boolean(String(email).toLowerCase().match(RegExp(emailRegex)));
// Hack to cleanup team+ks922@mindfulness.com emails where the
// + becomes a space because of next conversion.
export const cleanEmail = composel(trim, replace(/\s/gi, '+'));

export const slugify = (string?: string) => {
  const slug = when(string, (str) =>
    str
        .toLowerCase()
        .replace(/[^a-z0-9]+/gi, '-')
        .replace(/^-|-$/g, ''),
  );
  return slug;
};

/**
 * Gets a list of values between template strings.
 * @param {string} template - The template string.
 * @return {Array<Object>} - The list of values.
 * @example
 * Hello, ${name}
 * => [{value: name}]
 */
export const getTemplateValues = (template: string) => {
  // eslint-disable-next-line no-unused-vars
  const [_first, ...templates] = template.split('${');
  const initialVal: Array<{
    key?: string;
    value?: string;
    alt?: string;
  }> = [];
  const values = templates.reduce((prev, current, index, all) => {
    const key = current.split('}')[0];
    const [value, alt] = key.split('||');
    return [
      ...prev,
      omitEmpty({
        key,
        value,
        alt,
      }),
    ];
  }, initialVal);
  return values;
};

export const replaceAll = (search: string, replacement: string) => {
  return (str: string) =>
    when(str, (s) => s.replace(new RegExp(search, 'g'), replacement));
};

export const assertString = <T extends string>(
  str: Maybe<T> | null,
  assertTo?: string,
): string => {
  if (isString(str)) {
    return str;
  }
  if (assertTo) {
    return assertTo;
  }
  return '';
};

export const getSizedImage = ({
  size,
  src,
  portrait,
}: {
  src: string;
  portrait?: boolean;
  size: Maybe<{
    width: Maybe<number>;
    height?: number;
  }>;
}) => {
  if (!src) {
    return;
  }
  if (!size?.height || !size?.width || !src.includes('mindfulness.com')) {
    return src;
  }

  const match = src.match(/\.(jpg|jpeg|gif|png|tiff|tif|bmp|bitmap|webp)$/i);

  if (match !== null) {
    const prefix = src.split(match[0])[0].replace('.1000xauto', '');
    const suffix = match[0];
    // jpeg images do no support resizing
    if (!['.jpeg', '.jpg'].includes(suffix)) {
      return src;
    }
    const position = portrait ? 'cover_portrait' : 'cover_center';
    const retinaWidth = size.width * 2;
    const retinaHeight = size.height * 2;
    const cappedWidth =
      retinaWidth > 1000 || retinaHeight > 1000 ? size.width : retinaWidth;
    const cappedHeight =
      retinaWidth > 1000 || retinaHeight > 1000 ? size.height : retinaHeight;
    const newSrc = `${prefix}.${cappedWidth}x${cappedHeight}_${position}${suffix}`;
    return newSrc;
  }

  return src;
};

export const vttToPlainText = (vttCaption: string) => {
  if (vttCaption.length === 0) {
    return;
  }

  vttCaption = vttCaption.replace(/.+ --> .+/g, '');
  vttCaption = vttCaption.replace(/<\/c>/g, '');
  vttCaption = vttCaption.replace(/<.+?>/g, '');
  vttCaption = vttCaption.replace(/^\s*$/g, '');
  vttCaption = vttCaption.replace(/&nbsp;/g, ' ');

  const lines = vttCaption.split('\n');
  lines.splice(0, 4);
  const noSpaceLines = lines.map((line) => line.trim());
  const validLines = noSpaceLines.filter(
      (line) => line.length > 0 && isNaN(Number(line)),
  );
  const uniqLines = validLines.filter(
      (line, index, lines) => line !== lines[index + 1],
  );
  const splitLines = uniqLines.join(' ').split('. ');

  const chunks = splitLines
      .reduce((result, _val, index, array) => {
        if (index % 5 === 0) {
          result.push(array.slice(index, index + 5));
        }
        return result;
      }, [] as string[][])
      .map((chunk) => chunk.join('. '));

  const text = chunks.join(`.\n\n`);
  return text;
};

export const labelSplits = (label: Maybe<string> | null) => {
  if (!label) {
    return ['', ''];
  }
  return label.split(' · ');
};

export const splitLabel = (label: Maybe<string> | null) => {
  return labelSplits(label)[0];
};
