import {v4 as uuidv4} from 'uuid';
import {onClient} from '../utils/next';

/**
 * If we are on the client get the client id from the localStorage.
 * If there is no client id in the storage, generate a new one and store it.
 * @return {string}
 */
export const uniqueClientId = () =>
  onClient(() => {
    let clientId = localStorage.getItem('client_uuid');
    if (!clientId) {
      clientId = uuidv4();
      localStorage.setItem('client_uuid', clientId);
    }
    return clientId;
  });

export const uniqueSessionId = () =>
  onClient(() => {
    let clientId = sessionStorage.getItem('session_uuid');
    if (!clientId) {
      clientId = uuidv4();
      localStorage.setItem('session_uuid', clientId);
    }
    return clientId;
  });
