import {CSSProperties} from 'react';
import * as React from 'react';
import {Alignment, Padding} from '@mindfulness/cms';
import {Maybe, isDefined, when} from '@mindfulness/utils/maybe';
import {Theme} from '@emotion/react';
import {switchEnum} from '@mindfulness/utils/logic';

import styleUtils from '../styles/utils.module.css';

import {
  BreakpointRange,
  ColoursGradients,
  ResponsiveValue,
} from '../types/types';

export const sanityTextAlignmentMap = (align: Maybe<Alignment>) => {
  if (!align) return;
  const alignments = {
    start: 'left',
    center: 'center',
    end: 'right',
  } as const;
  return alignments[align];
};

export const sanityFlexAlignmentMap = (align: Alignment) => {
  const alignments = {
    start: 'flex-start',
    center: 'center',
    end: 'flex-end',
  } as const;
  return alignments[align];
};

export const buildPaddingMap = (padding: {
  paddingTop?: Padding;
  paddingBottom?: Padding;
}): {
  paddingTop?: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
  };
  paddingBottom?: {
    xs: number;
    sm: number;
    md: number;
    lg: number;
  };
} => {
  const convert = (p: Padding): {
    xs: number;
    sm: number;
    md: number;
    lg: number;
  } => ({
    xs: p / 1.3,
    sm: p / 1.2,
    md: p / 1.1,
    lg: p,
  });

  return {
    paddingTop: when(padding?.paddingTop, (t) => convert(t)),
    paddingBottom: when(padding?.paddingBottom, (b) => convert(b)),
  };
};

export const textColourMap = (
    colour?: ColoursGradients,
): keyof Theme['colors'] => {
  const textColours: Record<string, keyof Theme['colors']> = {
    highlight: 'white',
    white: 'grey9',
    warmGrey: 'grey9',
    greyWarm: 'grey9',
    grey9: 'white',
    primary: 'white',
    primaryGradient: 'white',
    darkGradient: 'white',
    darkCenterGradient: 'white',
    darkDarkGradient: 'white',
    softGradient: 'white',
    softLongGradient: 'white',
    grey9Gradient: 'white',
  };
  return textColours[colour || 'white'];
};

export const listColourMap = (colour: ColoursGradients) => {
  return switchEnum<ColoursGradients, 'primary' | 'white'>(colour, {
    white: 'primary',
    greyWarm: 'primary',
    grey9: 'white',
    else: 'primary',
  });
};

export const getSpan = (theme: Theme, span: Maybe<Span>, flex: boolean) => {
  if (span === undefined) {
    return `
      flex: 0 0 100%;
      max-width: 100%;
      ${flex ? 'display: flex;' : 'display: block;'}
    `;
  }
  if (typeof span === 'object') {
    return `
      ${getFlexDisplay(span.xs, flex)}
      ${
        span.sm !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.sm.min}) {
          ${getFlexDisplay(span.sm, flex)}
        }
      ` :
          ''
}
      ${
        span.md !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.md.min}) {
          ${getFlexDisplay(span.md, flex)}
        }
      ` :
          ''
}
      ${
        span.lg !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.lg.min}) {
          ${getFlexDisplay(span.lg, flex)}
        }
      ` :
          ''
}
      ${
        span.xl !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.xl.min}) {
          ${getFlexDisplay(span.xl, flex)}
        }
      ` :
          ''
}
    `;
  }
  return getFlexDisplay(span, flex);
};

export const getHide = (theme: Theme, hide: Maybe<BreakpointRange>) => {
  return hide ?
    `
@media (min-width: ${
        hide[0] === 'xs' ? '0px' : theme.breakpoints[hide[0]].min
      }) ${
        hide[1] === 'xxl' ?
          '' :
          `and (max-width: ${theme.breakpoints[hide[1]].max})`
      } {
  display: none;
}
` :
    '';
};
export const getBackground = (
    theme: Theme,
    bg: ResponsiveValue<ColoursGradients | keyof Theme['colors']>,
) =>
  buildResponsiveItems<ColoursGradients | keyof Theme['colors']>({
    theme,
    val: bg,
    prop: (v: string) =>
      v.includes('Gradient') ? 'background-image' : 'background',
    format: (bg) => {
      // If the background is a gradient, we get the gradient from the theme gradient map, otherwise we get the colour from the theme colour map.
      const formattedValue = `${
        bg.includes('Gradient') ?
          `${
            theme.gradients[
                bg.replace('Gradient', '') as keyof Theme['gradients']
            ]
          }` :
          `${theme.colors[bg as keyof Theme['colors']]}`
      }`;
      return formattedValue;
    },
  });
export const getColor = (theme: Theme, bg: ResponsiveValue<ColoursGradients>) =>
  buildResponsiveItems({
    theme,
    val: bg,
    prop: 'color',
    format: (bg: ColoursGradients) => {
      const color = bg ? theme.colors[textColourMap(bg)] : 'inherit';
      return color;
    },
  });
export const getFontSize = (
    theme: Theme,
    size: Maybe<ResponsiveValue<keyof Theme['fontSizes']>>,
) => {
  if (!isDefined(size)) {
    return '';
  }
  if (typeof size === 'object') {
    return buildResponsiveItems({
      theme,
      val: size,
      format: (val) => theme.fontSizes[val],
      prop: 'font-size',
    });
  }
  return `
    font-size: ${theme.fontSizes[size]};
  `;
};

export const getFontWeight = (
    theme: Theme,
    weight: Maybe<ResponsiveValue<keyof Theme['fontWeights']>>,
) => {
  if (!isDefined(weight)) {
    return '';
  }
  if (typeof weight === 'object') {
    return buildResponsiveItems({
      theme,
      val: weight,
      format: (val) => `${theme.fontWeights[val]}`,
      prop: 'font-weight',
    });
  }
  return `
  font-weight: ${theme.fontWeights[weight]};
`;
};

export const getWide = (theme: Theme, wide: Maybe<ResponsiveValue<boolean>>) =>
  buildResponsiveItems({
    theme,
    val: wide,
    prop: 'width',
    format: (w) => (w ? '100%' : ''),
  });

export const getRatio = (
    theme: Theme,
    ratio: ResponsiveValue<string | number>,
) =>
  buildResponsiveItems({
    theme,
    val: ratio,
    prop: 'padding-bottom',
    format: (v: Maybe<string | number>) => {
      if (typeof v === 'number') {
        return `${v * 100}%`;
      }
      const _ratio = when(v, (s) => {
        const [w, h] = s.split(':').map(Number);
        return w && h ? (h / w) * 100 : undefined;
      });

      return _ratio ? `${_ratio}%` : '';
    },
  });

export const getAlignItems = (
    theme: Theme,
    align: ResponsiveValue<CSSProperties['alignItems']>,
) =>
  buildResponsiveItems({
    theme,
    val: align as string,
    prop: 'align-items',
  });

export const getJustify = (
    theme: Theme,
    justify: ResponsiveValue<CSSProperties['justifyContent']>,
) =>
  buildResponsiveItems<CSSProperties['justifyContent']>({
    theme,
    val: justify,
    prop: 'justify-content',
  });

export const getColReverse = (
    theme: Theme,
    reverse: ResponsiveValue<boolean>,
) =>
  buildResponsiveItems({
    theme,
    val: reverse,
    prop: 'flex-direction',
    format: (v) => (v ? 'column-reverse' : 'column'),
  });

export const getRowReverse = (
    theme: Theme,
    reverse: ResponsiveValue<boolean>,
) =>
  buildResponsiveItems({
    theme,
    val: reverse,
    prop: 'flex-direction',
    format: (v) => (v ? 'row-reverse' : 'row'),
  });

export const getSpacing = (
    theme: Theme,
    spacing: Maybe<ResponsiveValue<keyof Theme['spacings']>>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'margin-bottom',
    format: (v) => theme.spacings[v],
  });

export const getTopPaddingValue = (
    theme: Theme,
    spacing: ResponsiveValue<number>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'padding-top',
    format: (v: number) => `${v / 16}rem`,
  });

export const getBottomPaddingValue = (
    theme: Theme,
    spacing: ResponsiveValue<number>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'padding-bottom',
    format: (v: number) => `${v / 16}rem`,
  });
export const getDisplay = (
    theme: Theme,
    val: ResponsiveValue<React.CSSProperties['display']>,
) =>
  buildResponsiveItems({
    theme,
    val: val as string,
    prop: 'display',
  });
export const getRadius = (
    theme: Theme,
    spacing: ResponsiveValue<keyof Theme['radius']>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'border-radius',
    format: (v) => theme.radius[v],
  });
export const getShadow = (
    theme: Theme,
    shadow: ResponsiveValue<keyof Theme['shadows']>,
) =>
  buildResponsiveItems({
    theme,
    val: shadow,
    prop: 'box-shadow',
    format: (v) => theme.shadows[v],
  });
export const getPadding = (
    theme: Theme,
    spacing: ResponsiveValue<keyof Theme['spacings']>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'padding',
    format: (v) => theme.spacings[v],
  });
export const getLeftPadding = (
    theme: Theme,
    spacing: ResponsiveValue<keyof Theme['spacings']>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'padding-left',
    format: (v) => theme.spacings[v],
  });
export const getRightPadding = (
    theme: Theme,
    spacing: ResponsiveValue<keyof Theme['spacings']>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'padding-right',
    format: (v) => theme.spacings[v],
  });

export const getLeftMargin = (theme: Theme, spacing: ResponsiveValue<number>) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'margin-left',
    format: (v: number) => `${v / 16}rem`,
  });
export const getRightMargin = (
    theme: Theme,
    spacing: ResponsiveValue<number>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'margin-right',
    format: (v: number) => `${v / 16}rem`,
  });
export const getTopMargin = (theme: Theme, spacing: ResponsiveValue<number>) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'margin-top',
    format: (v: number) => `${v / 16}rem`,
  });
export const getBottomMargin = (
    theme: Theme,
    spacing: ResponsiveValue<number>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'margin-bottom',
    format: (v) => `${v / 16}rem`,
  });
export const getTopPadding = (
    theme: Theme,
    spacing: ResponsiveValue<keyof Theme['spacings']>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'padding-top',
    format: (v) => theme.spacings[v],
  });
export const getBottomPadding = (
    theme: Theme,
    spacing: Maybe<ResponsiveValue<keyof Theme['spacings']>>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'padding-bottom',
    format: (v) => theme.spacings[v],
  });
export const getTopSpacing = (
    theme: Theme,
    spacing: Maybe<ResponsiveValue<keyof Theme['spacings']>>,
) =>
  buildResponsiveItems({
    theme,
    val: spacing,
    prop: 'margin-top',
    format: (v) => theme.spacings[v],
  });
export const getGridColumns = (theme: Theme, cols: ResponsiveValue<number>) =>
  buildResponsiveItems({
    theme,
    val: cols,
    prop: 'grid-template-columns',
    format: (v) => `repeat(${v}, minmax(0, 1fr))`,
  });
export const getFlexGap = (
    theme: Theme,
    gap: ResponsiveValue<keyof Theme['spacings']>,
) =>
  buildResponsiveItems({
    theme,
    val: gap,
    prop: 'gap',
    format: (v) => theme.spacings[v],
  });

export const getAlign = (theme: Theme, align: Maybe<TextAlignment>) =>
  when(align, (val) =>
    buildResponsiveItems({
      theme,
      val,
      prop: 'text-align',
      format: (v) => {
        if (v) {
          const align = sanityTextAlignmentMap(v);
          if (align) {
            return align;
          }
        }
        return '';
      },
    }),
  ) || '';

export const getOrder = (theme: Theme, order: ResponsiveValue<number>) =>
  when(order, (val) =>
    buildResponsiveItems({
      theme,
      val,
      prop: 'order',
    }),
  ) || '';

export const getColumnGap = (
    theme: Theme,
    val: ResponsiveValue<keyof Theme['spacings']>,
) =>
  buildResponsiveItems({
    theme,
    val,
    prop: 'column-gap',
    format: (v) => theme.spacings[v],
  });
export const getRowGap = (
    theme: Theme,
    val: ResponsiveValue<keyof Theme['spacings']>,
) =>
  buildResponsiveItems({
    theme,
    val,
    prop: 'row-gap',
    format: (v) => theme.spacings[v],
  });

export const getWidthSpacing = (
    theme: Theme,
    width: ResponsiveValue<keyof Theme['spacings']>,
) =>
  buildResponsiveItems({
    theme,
    val: width,
    prop: 'width',
    format: (v) => theme.spacings[v],
  });

export const getHeightSpacing = (
    theme: Theme,
    height: ResponsiveValue<keyof Theme['spacings']>,
) =>
  buildResponsiveItems({
    theme,
    val: height,
    prop: 'height',
    format: (v) => theme.spacings[v],
  });

export const getWidth = (
    theme: Theme,
    width: ResponsiveValue<number | string>,
) => buildResponsiveItems({theme, val: width, prop: 'width'});

export const getHeight = (theme: Theme, width: ResponsiveValue<string>) =>
  buildResponsiveItems({theme, val: width, prop: 'height'});

export const buildResponsiveCss = <
  V extends string | number | boolean | undefined
>({
    theme,
    val,
    format,
    withMax,
  }: {
  theme: Theme;
  val: Maybe<ResponsiveValue<V>>;
  format?: (v: V) => string;
  withMax?: boolean;
}) => {
  const fn = format || ((v: V) => v);
  if (typeof val === 'object') {
    return `
      ${
        withMax ?
          `@media (max-width: ${theme.breakpoints.sm.min}) {
        ${fn(val.xs)}
      }` :
          fn(val.xs)
}

      ${
        val.sm !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.sm.min}) ${
              withMax ? `and (max-width: ${theme.breakpoints.md.min})` : ''
          } {
          ${fn(val.sm)}
        }
      ` :
          ''
}
      ${
        val.md !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.md.min}) ${
              withMax ? `and (max-width: ${theme.breakpoints.lg.min})` : ''
          } {
          ${fn(val.md)}
        }
      ` :
          ''
}
      ${
        val.lg !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.lg.min}) ${
              withMax ? `and (max-width: ${theme.breakpoints.xl.min})` : ''
          } {
          ${fn(val.lg)}
        }
      ` :
          ''
}
      ${
        val.xl !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.xl.min}) {
          ${fn(val.xl)}
        }
      ` :
          ''
}
    `;
  }
  if (!val) return '';
  return fn(val);
};

/**
 * Takes a responsive value object and returns a css string for it.
 * @return {string} css string
 * @example
 * buildResponsiveItems({
 *   theme,
 *   val: '1fr',
 *   prop: 'grid-template-columns',
 *  format: (v) => `repeat(${v}, minmax(0, 1fr))`,
 * })
 * // => '@media (min-width: 0px) { grid-template-columns: repeat(1, minmax(0, 1fr)); }'
 */
export const buildResponsiveItems = <
  V extends string | number | boolean | undefined
>({
    theme,
    val,
    prop: propName,
    format,
  }: {
  theme: Theme;
  val: Maybe<ResponsiveValue<V>>;
  prop: string | ((v: V) => string);
  format?: (v: V) => string;
}) => {
  const defaultFn = (v: V) => v;
  const fn = format || defaultFn;
  const prop = (v: V) => {
    if (typeof propName === 'function') {
      return propName(v);
    }
    return propName;
  };

  if (typeof val === 'object') {
    return `
      ${prop(val.xs)}: ${fn(val.xs)};
      ${
        val.sm !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.sm.min}) {
          ${prop(val.sm)}: ${fn(val.sm)};
        }
      ` :
          ''
}
      ${
        val.md !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.md.min}) {
          ${prop(val.md)}: ${fn(val.md)};
        }
      ` :
          ''
}
      ${
        val.lg !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.lg.min}) {
          ${prop(val.lg)}: ${fn(val.lg)};
        }
      ` :
          ''
}
      ${
        val.xl !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.xl.min}) {
          ${prop(val.xl)}: ${fn(val.xl)};
        }
      ` :
          ''
}
    `;
  }
  if (!val) return '';
  return `${prop(val)}: ${fn(val)};`;
};

export const getGap = (
    theme: Theme,
    gap: ResponsiveValue<keyof Theme['spacings']>,
) => {
  if (typeof gap === 'object') {
    return `
      ${getGapMargins(theme, gap.xs)}
      ${
        gap.sm !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.sm.min}) {
          ${getGapMargins(theme, gap.sm)}
        }
      ` :
          ''
}
      ${
        gap.md !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.md.min}) {
          ${getGapMargins(theme, gap.md)}
        }
      ` :
          ''
}
      ${
        gap.lg !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.lg.min}) {
          ${getGapMargins(theme, gap.lg)}
        }
      ` :
          ''
}
      ${
        gap.xl !== undefined ?
          `
        @media (min-width: ${theme.breakpoints.xl.min}) {
          ${getGapMargins(theme, gap.xl)}
        }
      ` :
          ''
}
    `;
  }
  return getGapMargins(theme, gap);
};

const getGapMargins = (theme: Theme, gap: keyof Theme['spacings']) => `
margin-left: -${theme.spacings[gap]};
> * {
  padding-left: ${theme.spacings[gap]};
}
`;

const getFlexDisplay = (span: number, flex: boolean) => `
  ${
    span === 0 ?
      `
  display: none;
  ` :
      `
  ${flex ? 'display: flex;' : 'display: block;'}
  max-width: ${(span / 24) * 100}%;
  flex: 0 0 ${(span / 24) * 100}%;
  `
}
`;

export const stopGlobalPropForwarding = (prop: string) =>
  ![
    'as',
    'color',
    'lastItem',
    'buttonStyle',
    'background',
    'backgroundColor',
    'direction',
    'items',
    'height',
    'spacing',
    'display',
    'justify',
    'fontWeight',
    'transform',
    'sectionPadding',
    'flexDirection',
    'width',
    'textAlign',
    'loading',
    'maxWidth',
    'minWidth',
    'margin',
    'marginY',
    'marginR',
    'marginL',
    'marginB',
    'marginT',
    'marginX',
    'padding',
    'paddingB',
    'paddingR',
    'paddingL',
    'paddingT',
    'paddingX',
    'paddingY',
    'position',
    'overflow',
    'spacing',
    'wide',
    'radius',
    'shadow',
    'fontSize',
    'fontWeight',
    'zIndex',
    'gutter',
    'wrap',
  ].includes(prop);

type Span = ResponsiveValue<number>;

type TextAlignment = ResponsiveValue<Alignment>;


/**
 * Get the responsive class names for the a css property when the value is a boolean
 * @param {ResponsiveValue<T>} val - The value of the css property potentially an object of responsive values with breakpoints as keys
 * @param {string} prop - the CSS property name
 * @return {string[]} - An array of class names
 */
export function getResponsiveBoolClassNames(val: ResponsiveValue<boolean>, prop: string): string[] {
  if (!val) return [];
  return typeof val === 'object' ? Object.entries(val).filter(([_key, value]) => value).map(([key]) => styleUtils[`${key}-${prop}`]) : [
    styleUtils[`${prop}`],
  ];
}

/**
 * Get the responsive class names for the a css property
 * @param {ResponsiveValue<T>} val - The value of the css property potentially an object of responsive values with breakpoints as keys
 * @param {string} prop - the CSS property name
 * @return {string[]} - An array of class names
 */
export function getResponsiveClassNames<T>(val: ResponsiveValue<T>, prop: string): string[] {
  if (!val) return [];
  return typeof val === 'object' ? Object.entries(val).map(([key, value]) => styleUtils[`${key}-${prop}-${value}`]) : [
    styleUtils[`${prop}-${val}`],
  ];
}

/**
 * Get the responsive class names for the a css property, when the value is a variable
 * @param {ResponsiveValue<T>} val - The value of the css property potentially an object of responsive values with breakpoints as keys
 * @param {string} prop - the CSS property name
 * @return {string[]} - An array of class names
 */
export function getResponsiveVariableClassNames<T>(val: ResponsiveValue<T>, prop: string): string[] {
  if (!val) return [];
  return typeof val === 'object' ? Object.keys(val).map((key) => styleUtils[`${key}-${prop}`]) : [
    styleUtils[`xs-${prop}`],
  ];
}

/**
 * Get the responsive css variable names for the a css property
 * @param {ResponsiveValue<T>} val - The value of the css property potentially an object of responsive values with breakpoints as keys
 * @param {string} prop - the CSS property name
 * @return {string[]} - An array of class names
 */
export function getResponsiveVariables<T>(val: ResponsiveValue<T>, prop: string): Record<string, string> {
  if (!val) return {};
  if (typeof val === 'object') {
    return {
      ...Object.entries(val).reduce((acc, [key, value]) => ({
        ...acc,
        [`--${key}-${prop}`]: `${value}`,
      }), {}),
    };
  }
  return {
    [`--xs-${prop}`]: `${val}`,
  };
}
