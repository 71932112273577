import {KeyOf} from '../types/types';
import {isDefined} from '@mindfulness/utils/maybe';

/**
 * Turns an array into an object with the array values as keys and a default value for each.
 * @param {Array<KeyOf>} arr - The array you want to convert into an object
 * @param {T} defaultVal - The value to set each key to
 * @return {Record<KeyOf, T> | {}} - The newly created object
 */
export function createKeyedObject<K extends KeyOf, T>(
    arr: Array<K>,
    defaultVal: T,
): Record<K, T> {
  return arr.reduce(
      (acc, val) => ({...acc, [val]: defaultVal}),
      {},
  ) as Record<K, T>;
}

/**
 *
 * @param {Partial<Record<K, V>>} obj - The object to reduce
 * @param {function} op - The operation you want to apply to each value
 * @param {function} keyOp - The operation you want to apply to each key
 * @return {Record<KeyOf, T> | {}}
 */
export function reduceObject<K extends KeyOf, V>(
    obj: Partial<Record<K, V>>,
    op?: (val: V) => V | string,
    keyOp?: (key: K) => KeyOf,
): Partial<Record<K, V>> {
  const vOp = isDefined(op) ? op : (v: V) => v;
  const kOp = isDefined(keyOp) ? keyOp : (k: K) => k;
  const newObj = Object.keys(obj).reduce((acc, key) => {
    if (obj[key as K] === undefined) return acc;
    return {
      ...acc,
      [kOp(key as K)]: vOp(obj[key as K] as V),
    };
  }, {});
  return newObj;
}
