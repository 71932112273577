import {NextRouter} from 'next/router';
import {switchSpace} from './spaces';
import {AppRouterInstance} from 'next/dist/shared/lib/app-router-context';

export const downloadLink = () =>
  process.env.INFRA_ENV === 'prod' ?
    'https://share.mindfulness.com/WhRVk3ODp7' :
    'https://share.dev.mindfulness.com/cRCg9s9vW7';

export const APPSFLYER_BASE_URL =
  process.env.INFRA_ENV === 'prod' ?
    'https://link.mindfulness.com/iHSh' :
    'https://link.dev.mindfulness.com/xRNB';

export const GENERAL_SHORT_LINK =
  process.env.INFRA_ENV === 'prod' ? 'c559f816' : '7d9adf73';

export const mapAPIWebSlugs = (slug: string): string => {
  if (!slug) return '';
  return slug
      .replace('/beginner/', '/mindful-living/')
      .replace('/compassion/', '/self-compassion/')
      .replace('/family/', '/kids/');
};

export const defaultCheckoutThankyou = () => '/';
export const defaultLanderThankyou = () => switchSpace({
  'mindfulness.com': '/',
  'else': '/',
});

export const silentlyUpdateUrl = (router: NextRouter | AppRouterInstance, url: string) => {
  router.replace(url, undefined, {shallow: true});
};


export const defaultHomeFeed = () => 'web-home';

export const defaultNoAuthHomeFeed = () => 'web-home-no-auth';


export const loggedInItems = switchSpace({
  'mindfulness.com': [
    {label: 'Home', link: '/'},
    {label: 'Meditate', link: '/feed/meditate'},
    {label: 'Mini', link: '/feed/mini'},
    {label: 'Sleep', link: '/feed/sleep'},
    {label: 'Radio', link: '/feed/radio'},
  ],
  'else': [],
});

