import {getSession} from './auth';
import {onClient} from './next';
import {getValue, setValue} from './storage';

export const storePublicId = (id: string) =>
  onClient(() => {
    setValue('publicId', id);
  });

export const getPublicId = () =>
  onClient(() => {
    const publicId = getValue('publicId');
    const session = getSession();
    return publicId || session?.user.publicId;
  });
