import {when, Maybe} from '@mindfulness/utils/maybe';
import {omitEmpty} from '@mindfulness/utils/object';
import {composel} from '@mindfulness/utils/fn';
import {ifDo} from '@mindfulness/utils/logic';
import Cookies from 'js-cookie';

import {AuthStrategy, SessionFragment} from '../types/types';
import {clearValue, getValue, setValue} from './storage';
import {onClient, throwOnServer} from './next';

export interface UnauthedSession {
  email: string;
  exists: boolean;
  paid: boolean;
  publicId?: string;
}

export interface AuthedSession {
  token: string;
  strategy: AuthStrategy;
  email?: string;
  publicId?: string;
  paid?: boolean;
  firstName?: string;
  fullName?: string;
  userId?: string;
  user: SessionFragment['user'];
}

export type StoredSession = AuthedSession;

export const isAuthed = (
    session: Maybe<StoredSession>,
): session is AuthedSession => !!(session as Maybe<SessionFragment>)?.token;

export const toStoredSession = (session: SessionFragment): AuthedSession => {
  return {
    token: session.token,
    strategy: session.strategy,
    paid: session.user?.hasActiveSubscription || undefined,
    email: session.user?.email || undefined,
    publicId: session.user?.publicId,
    firstName: session.user?.firstName,
    fullName: session.user?.fullName,
    userId: session.user?.id,
    user: session.user,
  };
};

/* eslint-disable camelcase */
export const getAttributionData = () =>
  omitEmpty({
    current_path: window.location.pathname,
    utm_source: getValue('last-utm-source') || getValue('first-utm-source'),
    utm_medium: getValue('last-utm-medium') || getValue('first-utm-medium'),
    utm_campaign:
      getValue('last-utm-campaign') || getValue('first-utm-campaign'),
    utm_content: getValue('last-utm-content') || getValue('first-utm-content'),
    utm_term: getValue('last-utm-term') || getValue('first-utm-term'),
  });
/* eslint-enable camelcase */

/**
 * Get the currently stored user session
 * @return {Maybe<StoredSession>}
 */
export const getSession = (): Maybe<StoredSession> =>
  onClient(() =>
    when(
        getValue('auth_session'),
        composel(JSON.parse, (session) =>
          ifDo(
          // Authed session
              (session.token && session.strategy) ||
            // Unauthed session
            (session.email && session.exists),
              () => {
                if (!Cookies.get('auth_token') && session?.token) {
                  Cookies.set('auth_token', session.token, {
                    expires: 365,
                  });
                }
                if (!session.token && Cookies.get('auth_token')) {
                  clearSession();
                  return;
                }
                return session;
              },
          ),
        ),
    ),
  );


/**
 * Stringifies and stores the session in local storage
 * @param {StoredSession} session - The session data that you want to store
 * @return {StoredSession} - The session
 */
export const storeSession = (session: StoredSession): StoredSession =>
  throwOnServer(() => {
    setValue('auth_session', JSON.stringify(session));
    // Set cookie to be read on server side
    Cookies.set('auth_token', session.token, {
      expires: 365,
    });
    return session;
  });

export const clearSession = () =>
  throwOnServer(() => {
    clearValue('auth_session');
    Cookies.remove('auth_token');
    // Deprecated storage fields
    clearValue('api_token');
    clearValue('atoken');
    return undefined;
  });

export const hasSession = () => onClient(() => !!getSession());

export const getAuthToken = () =>
  onClient(() => {
    const session = getSession();
    if (!Cookies.get('auth_token') && session?.token) {
      Cookies.set('auth_token', session.token, {
        expires: 365,
      });
    }
    return isAuthed(session) ? session?.token : undefined;
  });

export const setAuthToken = (token: string) =>
  onClient(() => {
    return setValue('api_token', token);
  });

export const hasAuthToken = () => onClient(() => !!getAuthToken());
