import {Maybe} from '@mindfulness/utils/maybe';
import {isNumber} from 'lodash/fp';

/**
 * Forces a value to be a number
 * @param {Number} num - The value to force to be a number
 * @param {Number} assertTo - The value to return if the value is not a number, defaults to 0
 * @return {Number} - The value that we are asserting to or 0
 */
export const assertNumber = <T extends number>(
  num: Maybe<T> | null,
  assertTo = 0,
): number => {
  if (isNumber(num)) {
    return num;
  }
  return assertTo;
};

export const payIn3Price = (price: number) => {
  return Math.floor((price * 1.05) / 3);
};
