import {when} from '@mindfulness/utils/maybe';
import {first, last} from 'lodash';
import {PlayableTrack, Maybe, SingleDetailFragment, ChapterPositions} from '../types/types';

export const toPlayable = (
    single: SingleDetailFragment,
    variantId: Maybe<string>,
): Maybe<PlayableTrack[]> => {
  const variantIndex = single?.variants?.findIndex(
      ({id}) => variantId === id,
  );
  return when(
      single?.variants?.[variantIndex || 0]?.chapters || undefined,
      (chapters) =>
        chapters.map(
            (
                {chapterType, chapterLabel, type, duration, id, mimeType, transcript, ...c},
                index,
            ) => ({
              transcript: transcript || undefined,
              source: c.source?.url || '',
              chapterType,
              chapterLabel,
              mimeType,
              skip: !!single.userData?.lastPlayedPercent && !!c.skipOnReplay,
              id,
              type,
              poster: c.initialFrame?.url || '',
              duration,
              index,
            }),
        ),
  );
};

export const getChapterPositionString = (tracks: Maybe<Array<PlayableTrack>>, index: number): ChapterPositions => {
  if (!tracks?.length) return 'UNKNOWN';
  if (!tracks[index]) return 'UNKNOWN';
  if (tracks.length === 1) return 'ONLY';
  if (index === 0) return 'FIRST';
  if (index === tracks.length - 1) return 'LAST';
  return 'MIDDLE';
};

export const noPreviousChapter = (
    chapter: Maybe<PlayableTrack>,
    tracks: Maybe<Array<PlayableTrack>>,
): boolean => {
  const firstTrack = first(tracks);
  return Boolean(firstTrack?.id === chapter?.id);
};

export const noNextChapter = (
    chapter: Maybe<PlayableTrack>,
    tracks: Maybe<Array<PlayableTrack>>,
): boolean => {
  const lastTrack = last(tracks);
  return Boolean(lastTrack?.id === chapter?.id);
};

export const hasNextChapter = (
    chapter: Maybe<PlayableTrack>,
    tracks: Maybe<Array<PlayableTrack>>,
): boolean => !noNextChapter(chapter, tracks);

export const hasPreviousChapter = (
    chapter: Maybe<PlayableTrack>,
    tracks: Maybe<Array<PlayableTrack>>,
) => !noPreviousChapter(chapter, tracks);

export const previousChapter = (
    chapter: Maybe<PlayableTrack>,
    tracks: Maybe<Array<PlayableTrack>>,
) => {
  if (!chapter || !tracks) return;
  return tracks[chapter?.index - 1];
};

export const nextChapter = (
    chapter: Maybe<PlayableTrack>,
    tracks: Maybe<Array<PlayableTrack>>,
) => {
  if (!chapter || !tracks) return;
  return tracks[chapter?.index + 1];
};

export const getTrackDurations = (tracks: Maybe<Array<PlayableTrack>>) => tracks?.reduce((prev, track) => prev + (track.duration || 0), 0) || 0;
