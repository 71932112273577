import {Fn} from '@mindfulness/utils/fn';
import {switchEnum} from '@mindfulness/utils/logic';

export const switchSpace = <E extends string | number | symbol, R>(options: {
  [P in E]?: R | Fn<P, R>;
} & {
  else: R | Fn<E, R>;
}): R => {
  const space = process.env.MINDFULNESS_SPACE as (MindfulnessSpaces & 'else');
  return switchEnum(space, options);
};


type MindfulnessSpaces = 'mindfulness.com' | 'mindful.org'
