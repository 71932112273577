// / <reference types="@types/segment-analytics" />
import dayjs from 'dayjs';
import crypto from 'crypto';
import {omitEmpty} from '@mindfulness/utils/object';
import {now} from '@mindfulness/utils/time';
import {Maybe, when} from '@mindfulness/utils/maybe';
import {composel, using} from '@mindfulness/utils/fn';
import {justOne} from '@mindfulness/utils/array';
import Cookie from 'js-cookie';
import queryString from 'query-string';

import {SessionFragment} from '../types/types';
import {getAttributionData} from './auth';
import {toSegmentCampaign, utmPropsFromUrl, whenNotEmpty} from './url';
import {onClient} from './next';
import {debug} from './logging';
import {UseIdentify, UseTrack} from '../components/global/SegmentProvider';
import {isDesktop, isMobile, isTablet} from 'react-device-detect';


const format = (date: Date, format: string) => dayjs(date).format(format);

const throwHandler = () => {
  console.error(Error('Only call tracking clientside.'));
};

export const segmentCampaign = composel(
    queryString.parse,
    utmPropsFromUrl,
    toSegmentCampaign,
    whenNotEmpty,
);

const fallbackAF = () => {
  console.error(new Error('Appsflyer SDK not loaded on client.'));
};

export const appsflyer = () => onClient(() => window.AF ?? fallbackAF) ?? throwHandler;

export const defaultIdentifyProps = (
    props: Record<string, any> = {},
): Record<string, any> => ({
  web_version_number: process.env.WEB_VERSION,
  // Set last stored utm props on every identify
  ...getAttributionData(),

  // Filter out any values that should not be tracked
  ...omitEmpty({
    name: props.name,
    email: props.email,
  }),
});

export const defaultEventProps = (
    props: Record<string, any> = {},
): Record<string, any> =>
  using(
      [now(), queryString.parse(location.search), getAttributionData()],
      (timestamp, params, lastStoredUtm) =>
      omitEmpty({
        email: justOne(params.email),

        // Set last-stored utm props on every event
        ...when(lastStoredUtm, (d) => ({
          last_utm_source: d.utm_source,
          last_utm_medium: d.utm_medium,
          last_utm_campaign: d.utm_campaign,
          last_utm_content: d.utm_content,
          last_utm_term: d.utm_term,
        })),

        // Set current page utm props on every event
        ...utmPropsFromUrl(params),

        ...props,

        // Additional global properties
        platform: 'WEB',
        version_number: process.env.npm_package_version,
        environment: process.env.INFRA_ENV,

        timestamp: timestamp,
        timestamp_hour: format(timestamp, 'HH'),
        timestamp_day: format(timestamp, 'DD'),
        timestamp_day_of_week:
          format(timestamp, 'E') === '7' ? '0' : format(timestamp, 'E'),
        timestamp_day_of_year: format(timestamp, 'DDDD'),
        // eslint-disable-next-line new-cap
        timezone: Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone,
        timezone_offset: timestamp.getTimezoneOffset() * -1,
        device_type: isMobile ? 'MOBILE' : isTablet ? 'TABLET' : isDesktop ? 'DESKTOP' : 'OTHER',
      }) as Record<string, any>,
  );


export const getSegmentAnonymousID = () => {
  return onClient(() => {
    const id = Cookie.get('segment_anonymous_id');
    return id;
  });
};

export const isNewUser = (accountCreatedAt: Maybe<string>): boolean => {
  const createdAt = when(accountCreatedAt, (c) => dayjs(c));
  // return the difference between the number of days today and the created at day
  const diff = when(createdAt, (c) => dayjs().diff(c, 'day'));
  // if the diff is less than 1 it was created today
  return Boolean(diff !== undefined && diff < 1);
};

export const trackNewOrExistingUsers = async (session: SessionFragment, {
  identify,
  track,
}: {
  identify: UseIdentify
  track: UseTrack
}) => {
  try {
    await identify(
        omitEmpty({
          email: session.user.email,
          name: session.user.fullName,
        }),
        session.user.publicId,
    );
    handleImpactConversion(session);
    if (!isNewUser(session.createdAt)) {
      await track('User already exists', {});
    } else {
      await track('User signed up', {
        auth_strategy: session.strategy,
      });
    }
    /**
     * Currently attributing all signup and logins to the impact partner
     */
    await track('Lead', {});
  } catch (err) {
    console.error('Failed to track new or existing users');
    return;
  }
};

const handleImpactConversion = (data: SessionFragment) => {
  debug('handleImpactConversion', data);
  const shasum = crypto.createHash('sha1');
  const impactEmail = data.user.email ?? '';
  const sha1Email =
    impactEmail !== '' ? shasum.update(impactEmail).digest('hex') : impactEmail;
  /**
   * TODO: store id in env vars
   */
  ire(
      'trackConversion',
      '32155',
      {
        orderId: data.user.publicId ?? '',
        customerId: data.user.publicId ?? '',
        customerEmail: sha1Email,
      },
      {
        verifySiteDefinitionMatch: true,
      },
  );
};

declare const ire: (
  name: string,
  params1: Record<string, string | boolean> | string,
  params2: Record<string, string | boolean>,
  params3: Record<string, string | boolean>
) => void;

export type Campaign = {
  name: string
  source: string
  medium: string
  [key: string]: string
}
