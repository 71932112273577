import {delay} from 'lodash/fp';
import {SingleFragment} from '../types/api';
import {FilteredMixedFragment, MixedFragment} from '../types/types';


export const filterPlayerSingles = (
    singles: Array<MixedFragment>,
): Array<FilteredMixedFragment> => {
  return singles?.filter((item: MixedFragment) => {
    if (item?.__typename === 'Single') {
      const single = item as unknown as SingleFragment;
      if (single.singleStatus === 'LIVE') {
        return true;
      }
      return false;
    }

    return false;
  }) as unknown as Array<FilteredMixedFragment>;
};


// Resolves after the promise AND at least the given time
export const atLeast = async <R>(prom: () => Promise<R> | R, time: number) => {
  const [res] = await Promise.all([prom(), delay(time)]);
  return res;
};
