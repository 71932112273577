import {UrlObject} from 'url';
import {join, keys} from 'lodash';
import {NextRouter} from 'next/router';
import {omitEmpty} from '@mindfulness/utils/object';
import {ensureArray} from '@mindfulness/utils/array';
import {isString, Maybe, when} from '@mindfulness/utils/maybe';
import {using} from '@mindfulness/utils/fn';
import {justOne} from '@mindfulness/utils/array';

import queryString, {ParsedQuery} from 'query-string';
import {onClient} from './next';
import {Campaign} from './analytics';

/* eslint-disable camelcase */
export interface UtmProps {
  utm_source: string;
  utm_campaign: string;
  utm_medium: string;
  utm_content: string;
  utm_term: string;
}

export interface AppsflyerProps {
  '~feature': string;
  '~channel': string;
  '~campaign': string;
}
/* eslint-enable camelcase */

export const toUrl = (opts: string | UrlObject) => {
  if (isString(opts)) {
    return opts;
  }

  const query = isString(opts.query) ?
    opts.query :
    when(opts.query || undefined, queryString.stringify);
  return `${opts.pathname}?${query || ''}`;
};

export const toPageId = (path: string) =>
  path.replace(/^\//gi, '').replace(/\//g, '.').split('?')[0];

export const removeQueryParam = (url: string, key: string) =>
  url.replace(new RegExp(`&?${key}=[\\w\\d-_.]*`, 'i'), '');

export const toSegmentCampaign = (props: Partial<UtmProps>): Campaign => ({
  name: props.utm_campaign || '',
  source: props.utm_source || '',
  medium: props.utm_medium || '',
  content: props.utm_content || '',
  term: props.utm_term || '',
});

export const whenNotEmpty = <T>(t: Record<string, Maybe<T>>) =>
  keys(omitEmpty(t)).length > 0 ? t : undefined;

export const utmPropsFromUrl = (
    params?: NextRouter['query'] | ParsedQuery<string>,
): Partial<UtmProps> =>
  using([params || onClient(() => queryString.parse(location.search)) || {}], (p) =>
    omitEmpty({
      // Additional global properties
      utm_campaign:
        justOne(p.utm_campaign || p.UTM_CAMPAIGN || p.c) || undefined,
      utm_source: justOne(p.utm_source || p.UTM_SOURCE || p.pid) || undefined,
      utm_medium:
        justOne(p.utm_medium || p.UTM_MEDIUM || p.af_channel) || undefined,
      utm_content:
        justOne(p.utm_content || p.UTM_CONTENT || p.af_adset) || undefined,
      utm_term:
        p.utm_term || p.UTM_TERM || p.af_keywords ?
          join(ensureArray(p.utm_term || p.UTM_TERM || p.af_keywords), ', ') :
          undefined,
    }),
  );

export const utmToDeeplink = (
    params?: NextRouter['query'],
): Partial<AppsflyerProps> =>
  omitEmpty({
    '~feature':
      justOne(params?.utm_medium || params?.UTM_MEDIUM || params?.af_channel) ||
      undefined,
    '~channel':
      justOne(params?.utm_source || params?.UTM_SOURCE || params?.pid) ||
      undefined,
    '~campaign':
      justOne(params?.utm_campaign || params?.UTM_CAMPAIGN || params?.c) ||
      undefined,
  });


export const mapTranscriptUrl = (url: string) => {
  const parsed = new URL(url);
  const next = `/media${parsed.pathname}`;
  return next;
};


export const cleanVerifyUrlParams = (link: string | undefined) => {
  return when(link, (v) => {
    const [pathname, search] = v.split('?');
    const oldParams = new URLSearchParams(search);
    const newParams = new URLSearchParams(omitEmpty({
      onto: oldParams.get('onto') || undefined,
      sharer_name: oldParams.get('sharer_name') || undefined,
      sharer_id: oldParams.get('sharer_id') || undefined,
      link: oldParams.get('link') || undefined,
      link_id: oldParams.get('link_id') || undefined,
      variant: oldParams.get('variant') || undefined,
      ty: oldParams.get('ty') || undefined,
      pu: oldParams.get('pu') || undefined,
    }));
    return `${pathname}?${newParams.toString()}`;
  });
};


export const buildReferrerUrl = (url: string) => {
  return cleanVerifyUrlParams(url);
};
